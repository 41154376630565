<template>
  <div class="getStoreName">
    <el-button class="subBtn" type="primary" @click="returnPickingconfirmation"
      >我已选择</el-button
    >
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import EventBus from "@/eventbus.js";
export default {
  data() {
    return {
      adpas: {},
    };
  },
  created() {
    let { adpas } = this.$route.query || {};
    if (adpas) {
      this.adpas = JSON.parse(adpas);
      this.initH5Message();
    }
  },
  methods: {
    returnPickingconfirmation() {
      Api.storeselectstoreresult().then((res) => {
        let results = res.data.result || {};
        if (typeof results === "string") {
          EventBus.postMessage(results);
          setTimeout(() => {
            window.close();
          }, 1000);
        } else {
          this.$message.error(`请重新选择`);
        }
      });
    },
    initH5Message() {
      Api.storeselectstore(this.adpas).then((res) => {
        let results = res.data.result || [];
        if (results && results.formData) {
          document.querySelector("body").innerHTML = res;
          const div = document.createElement("divformPickingconfirmation"); // 创建div
          div.innerHTML = results.formData;
          document.body.appendChild(div);
          document.forms[0].submit();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.getStoreName {
  padding: 100px 30px 30px 30px;
  display: flex;
  justify-content: flex-end;
}
</style>
